<template>
  <div
    style="width: 50px"
    class="fixed left-0 top-0 h-full bg-gray-100 border-0 border-r border-solid border-gray-200"
  >
    <nav id="sidebar">
      <ul>
        <li v-for="menu in availableNavs" :key="menu.title">
          <router-link
            class="flex flex-col items-center text-xs text-center"
            :to="menu.route"
          >
            <i class="font-bold text-xl" :class="menu.icon"></i>
            <!--            {{ menu.title }}-->
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MainSidebar",
  data: () => ({
    sidebarMenuItems: [
      {
        title: "Склад",
        icon: "el-icon-coin",
        route: { name: "storage" },
        role: ["admin"],
      },
      {
        title: "Kaspi",
        icon: "el-icon-suitcase",
        route: { name: "kaspi-onsale-products" },
        role: ["admin"],
      },

      // {
      //   title: "Демпинг",
      //   icon: "el-icon-sort",
      //   route: { name: "damping" },
      //   role: ["admin"],
      // },
      {
        title: "Накладные",
        icon: "el-icon-printer",
        route: { name: "waybills" },
        role: ["admin", "shymkent"],
      },
      {
        title: "Предзаказ",
        icon: "el-icon-time",
        route: { name: "preorder" },
        role: ["admin"],
      },
      {
        title: "Отзывы",
        icon: "el-icon-star-off",
        route: { name: "reviews" },
        role: ["admin", "reviewer"],
      },
      {
        title: "Счета",
        icon: "el-icon-money",
        route: { name: "accounts" },
        role: ["admin"],
        user_id: 1,
      },
      {
        title: "Отчеты",
        icon: "el-icon-document",
        route: { name: "reports" },
        role: ["admin"],
        user_id: 1,
      },
      {
        title: "Демпинг",
        icon: "el-icon-pie-chart",
        route: { name: "summary" },
        role: ["admin"],
        user_id: 1,
      },
      {
        title: "Сулпак",
        icon: "el-icon-monitor",
        route: { name: "sulpak" },
        role: ["admin"],
        user_id: 1,
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    availableNavs() {
      return this.sidebarMenuItems.filter((menu) =>
        menu.role.includes(this.user.role)
      );
    },
  },
};
</script>

<style lang="scss">
#sidebar {
  ul {
    @apply m-0 mt-2 p-0 flex flex-col gap-y-1 items-center;
    li {
      @apply w-9 h-9 flex-shrink-0;

      &:hover {
        a {
          @apply bg-gray-200;
        }
      }

      a {
        @apply rounded-lg border border-solid border-transparent text-gray-600 w-full h-full flex items-center justify-center;
        &.router-link-active {
          @apply bg-white border-gray-300 text-blue-500;
        }
      }
    }
  }
}
</style>
