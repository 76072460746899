import apiClient from "@/api/apiClient";
import LocalStorageService from "@/services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export const login = async (username, password) => {
  const result = await apiClient.post("/auth", {
    username,
    password,
  });
  let { access_token, refresh_token } = result.data;
  console.log(access_token, refresh_token);
  localStorageService.setToken({
    access_token: access_token,
    refresh_token: refresh_token,
  });
};

export const whoami = async () => {
  const result = await apiClient.get("/auth/me");
  return result.data;
};
