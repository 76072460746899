<template>
  <div class="text-sm font-normal" id="app">
    <main-sidebar v-if="user"></main-sidebar>
    <main-header></main-header>
    <div
      style="
        margin: 0 0 0 50px;
        max-height: calc(100vh - 50px);
        overflow-y: auto;
        padding: 30px;
      "
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MainSidebar from "@/components/MainSidebar";
import MainHeader from "@/components/MainHeader";

export default {
  name: "app",
  components: {
    MainSidebar,
    MainHeader,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss">
.island-drawer {
  height: calc(100% - 16px);
  top: 8px !important;
  right: 8px !important;

  .el-drawer {
    @apply rounded overflow-hidden;
  }
}
.el-drawer__body {
  overflow-y: auto !important;
}
.el-loading-spinner .circular svg {
  display: inline;
}

.has-error {
  .el-input {
    .el-input__inner {
      border-color: red;
    }
  }
}

.el-table .warning-row {
  background: oldlace;
}

.el-select-dropdown {
  max-width: 300px;
  .el-select-dropdown__wrap {
    .el-select-dropdown__item {
      width: 100%;
      height: auto;
      line-height: 1.8;
      font-size: 13px;
      padding: 3px 10px 3px 15px;
      span {
        overflow: visible;
        text-overflow: unset;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
  }
}
</style>
