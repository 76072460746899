import Vue from "vue";
import App from "./App.vue";
import { getRouter } from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./plugins/element.js";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { whoami } from "@/api/auth";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
  message: "Обязательное поле",
});

extend("min", {
  validate(value) {
    return {
      required: true,
      valid: value > 0,
    };
  },
  computesRequired: true,
  message: "Процент должен быть больше 0",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

const init = async () => {
  let role = "initial";

  try {
    const user = await whoami();
    store.commit("setUser", user.me);
    role = user.me.role;
  } catch (e) {
    console.log(e.message);
  }

  new Vue({
    router: getRouter(role),
    store,
    render: (h) => h(App),
  }).$mount("#app");
};

init();
