import axios from "axios";
import LocalStorageService from "@/services/LocalStorageService";
const DOMAIN = process.env["VUE_APP_DOMAIN"];
const apiClient = axios.create();
apiClient.defaults.baseURL = DOMAIN;

const localStorageService = LocalStorageService.getService();

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    console.log(error.response.status, originalRequest.url);
    if (
      error.response.status === 401 &&
      originalRequest.url === `auth/refresh`
    ) {
      console.log("ERROR");
      localStorageService.clearToken();
      location.hash = "#/auth";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return apiClient
        .post("auth/refresh", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          console.log(res);
          let { access_token } = res.data;
          localStorageService.setAccessToken({
            access_token: access_token,
          });
          apiClient.defaults.headers.common["Authorization"] =
            "Bearer " + localStorageService.getAccessToken();
          return apiClient(originalRequest);
        });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
